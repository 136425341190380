import React, { useState } from 'react';
import { Image } from '../utils/image.js';
import Measure from 'react-measure';

import twitter from './icons/twitter.svg';
import instagram from './icons/instagram.svg';

import './Home.css';

function Home() {
	const [{ width, height }, setSize] = useState({width: 0, height: 0});

	return <Measure
		client
		onResize={contentRect => {
			setSize(contentRect.client);
		}}
	>
		{({ measureRef }) => (
			<div id="home" ref={measureRef}>
				<Image
					id="content-image"
					src={"/assets/content.png"}
					ratio={1585/1367}
					blurhash={{
						hash: "-UFpRhNyAlw_abaws]WYsjWUWloM1FsSe-SLoNS5oZn~NZj[R:smogj[w^o2WrjYj;j?S5flxHS5W7WUSia{j]j^NZbIspjYoKWV",
					}}
					parent_size={{
						width: width,
						height: height - 100,
					}}
					alt="Alexandra Golici"
				/>
				<div className="footer">
					<a className="instagram" href="https://www.instagram.com/alexandragolici"><img alt="instagram" src={instagram}/></a>
					<a className="twitter" href="https://www.twitter.com/alexandragolici"><img alt="twitter" src={twitter}/></a>
				</div>
			</div>
		)}
	</Measure>
}

export default Home;