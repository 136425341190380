import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";

import Header from "./Header";
import Home from "./Home";
import About from "./About";
import NotFound from "./NotFound";
import Contact from "./Contact";
import Portfolio from "./Portfolio";

function App() {
	return <div id="app">
		<Header />

		<Switch>
			<Route exact path="/about">
				<About />
			</Route>
			<Route exact path={["/", "/home"]}>
				<Home />
			</Route>
			<Route exact path="/contact">
				<Contact />
			</Route>
			<Route exact path="/portfolio">
				<Portfolio />
			</Route>
			<Route>
				<NotFound />
			</Route>
		</Switch>
	</div>;
}

export default App;
