import React, { useState, useEffect } from 'react';
import { Blurhash } from "react-blurhash";

// Determines the largest size image that will fit within the parent_size
// Will use netlify's large media asset transformations to get that size image
// Will default to presenting a blurhash until the image is loaded
export function Image({src, ratio, blurhash, parent_size, alt, ...rest}) {
	const [width, setWidth] = useState(0);
	const [loaded, setLoaded] = useState(null);
	const [loading, setLoading] = useState(false);
	const [cropSrc, setSrc] = useState(src);

	useEffect(() => {
		if (!parent_size || !parent_size.width || !parent_size.height) {
			return
		}

		let width = parent_size.width;
		let pratio = parent_size.height / parent_size.width;
		if (pratio <= ratio) {
			width = parent_size.height / ratio;
		}
		setWidth(width);

		if (loading) {
			return
		}

		const cropSrc = `${src}?nf_resize=fit&w=${Math.round(width)}&h=${Math.round(width * ratio)}`;
		setSrc(cropSrc);

		setLoading(true);
		let image = document.createElement("img");
		image.src = cropSrc;
		image.addEventListener("load", () => {
			setLoaded(image);
		});
		if (image.completed) {
			setLoaded(image);
		}
	}, [src, parent_size, ratio, loading]);

	if (loaded) {
		return <img alt={alt} src={cropSrc} width={width} height={width*ratio} {...rest} />;
	} else {
		return <Blurhash
			width={width}
			height={width * ratio}
			resolutionX={32}
			resolutionY={32}
			punch={1}
			{...blurhash}
			{...rest}
		/>;
	}
}