import React, { useState } from 'react';
import './Header.css';
import { NavLink } from 'react-router-dom';
import { Menu } from './Menu';

function Header() {
	const [active, setActive] = useState(false);

	return <div id="header">
		<img alt="Alexandra Golici" src="/assets/header.png" />
		<Menu active={active} toggle={()=>setActive(b=>!b)} />
		<div id="links" className={active ? "show" : undefined}>
			<NavLink exact to="/">Home</NavLink>
			<NavLink exact to="/about">About</NavLink>
			<NavLink exact to="/portfolio">Portfolio</NavLink>
			<NavLink exact to="/contact">Contact</NavLink>
		</div>
	</div>;
}

export default Header;